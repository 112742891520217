import { TAG_CLOUD_WIDGET_SUCCESS, TAG_CLOUD_WIDGET_ENABLED } from './actions';

const tagCloudWidgetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TAG_CLOUD_WIDGET_ENABLED:
      return { ...state, enabled: true };
    case TAG_CLOUD_WIDGET_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default tagCloudWidgetReducer;
