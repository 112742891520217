import { get } from 'lodash';
import { isSSR } from '@wix/communities-blog-client-common';
import { fetchHashtagPosts } from '../../../common/actions/fetch-hashtag-posts';
import { HASHTAG_PAGE } from '../../../common/services/detect-route';
import { pageOpened } from '../../../common/actions/page-opened';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import { ROUTE_404 } from '../../constants/routes';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { generateHashtagSEOTags } from '../../../common/services/generate-seo-tags/generate-hashtag-seo-tags';
import { isSite } from '../../../common/store/basic-params/basic-params-selectors';

export const createHashtagPageRouter =
  (store, wixCodeApi) =>
  async ({ params }, redirect) => {
    !isSSR(store.getState()) && store.dispatch(pageOpened({ page: HASHTAG_PAGE }));
    const page = parseInt(get(params, 'pageIndex', '1'), 10);
    const { hashtag } = params;
    if (hashtag) {
      try {
        const posts = await store.dispatch(fetchHashtagPosts(hashtag, page));
        if (!posts.length) {
          return redirect(ROUTE_404);
        }
        store.dispatch(fetchProfileUrls());
        const state = store.getState();
        if (isSite(state)) {
          const hashtagSEOTags = generateHashtagSEOTags({
            state,
            hashtag,
            page,
            posts,
          });
          wixCodeApi.seo.renderSEOTags(hashtagSEOTags);
        } else {
          setMetaTagRobotsNoIndex(wixCodeApi);
        }
      } catch (error) {
        if (error.status === 404) {
          redirect(ROUTE_404);
        }
      }
    } else {
      redirect(ROUTE_404);
    }
  };
