import { get } from 'lodash';
import { ARCHIVE_PARAMS } from '@wix/communities-blog-client-common';

import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { setLocale, setQueryLocaleAction } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { getInstance } from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { fetchPostStatsByDate } from '../../common/store/post-stats-by-date/post-stats-by-date-actions';
import { fetchArchiveWidgetInitialData } from '../actions/fetch-initial-data';
import { archiveWidgetEnabled } from '../aggregated-archive/actions';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  translationsName,
  context = {},
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  const isArchiveWidgetAdapterExperimentEnabled = get(appParams, 'baseUrls.useArchiveWidgetAdapter') === 'true';
  if (isArchiveWidgetAdapterExperimentEnabled) {
    store.dispatch(archiveWidgetEnabled());
    await Promise.all([
      store.dispatch(setAppSettings({ style: config.style.styleParams })),
      store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber })),
      store.dispatch(setAppConfig({ bundleName })),
      store.dispatch(fetchArchiveWidgetInitialData()).then(() => store.dispatch(fetchTopology(instanceId))),
    ]);
    return;
  }

  await Promise.all([
    store.dispatch(fetchExperiments(context.experimentsPromise)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(fetchTranslations(language, translationsName)),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber })),
    store.dispatch(setAppConfig({ bundleName })),
  ]);
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);

  const monthsDisplayLimit =
    isEditor || isPreview
      ? ARCHIVE_PARAMS.monthsDisplayLimit.maxValue
      : getAppSettingsValue({
          state: store.getState(),
          key: ARCHIVE_PARAMS.monthsDisplayLimit.appSettingsPath,
          fallback: ARCHIVE_PARAMS.monthsDisplayLimit.defaultValue,
        });
  await store.dispatch(fetchPostStatsByDate(monthsDisplayLimit));
}
