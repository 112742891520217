import { ARCHIVE_WIDGET_SUCCESS, ARCHIVE_WIDGET_ENABLED } from './actions';

const archiveWidgetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ARCHIVE_WIDGET_ENABLED:
      return { ...state, enabled: true };
    case ARCHIVE_WIDGET_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default archiveWidgetReducer;
